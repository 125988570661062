import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Authenticated from "../components/authenticated";
import { useGlobalState } from "../lib/GlobalState";
import payzone from "../assets/images/devices/payzone.png";
import fullCounter from "../assets/images/devices/full-counter.png";
import selfService from "../assets/images/devices/self-service.png";
import Select from "react-select";
import { Device } from "../openapi/device-api/api";
import { Constants } from "../constants";
import Cross from "../assets/images/cross.png";
import { useRefreshPage } from "../lib/hooks/useRefreshPage";
interface DeviceType {
  id: string;
  name: string;
  enabled: boolean;
  image: string;
  label?: JSX.Element;
}

const deviceTypes: DeviceType[] = [
  {
    id: "payzone",
    name: Constants.PAYZONE,
    enabled: true,
    image: payzone,
  },
  {
    id: "counter",
    name: Constants.COUNTER_TERMINAL,
    enabled: true,
    image: fullCounter,
  },
  {
    id: "mobile",
    name: Constants.MOBILE,
    enabled: true,
    image: payzone,
  },
  {
    id: "mobiledrs",
    name: Constants.MOBILE_DRS,
    enabled: true,
    image: payzone,
  },
  {
    id: "sandbox",
    name: Constants.SANDBOX,
    enabled: true,
    image: selfService,
  },
];

const Setup = (): JSX.Element => {
  const { setDeviceConfig, deviceConfig, devices, getDevices, isLoading } = useGlobalState();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [error, setError] = useState<string | undefined>();
  const { saveDeviceName } = useRefreshPage(undefined);

  const handleOnDeviceChange = (item) => {
    setSelectedOption(item);
    setDeviceConfig((deviceConfig) => ({
      ...deviceConfig,
      deviceId: item.id,
    }));
    if (item.id !== (selectedOption && selectedOption.id)) {
      setSelectedDevice(null);
      setDeviceConfig((deviceConfig) => ({
        ...deviceConfig,
        terminalId: null,
      }));
    }
  };

  const handleOnTerminalChange = (item) => {
    setDeviceConfig((deviceConfig) => ({
      ...deviceConfig,
      terminalId: item.id,
    }));
    setSelectedDevice(item);
    saveDeviceName(item.id);
  };

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setError(null);
  }, [selectedDevice, selectedOption]);

  const onStartSimulatorClicked = async () => {
    for (let [key, value] of Object.entries(deviceConfig)) {
      if (key === "terminalId" && parseInt(value) === 0) {
        alert(Constants.PLEASE_ENTER_TERMINAL_ID + value + " " + parseInt(value).toString());
        return;
      }

      if (!value) {
        setError("Please select Device Name to start simulator");
        return;
      }
    }

    if (deviceConfig.terminalId !== null) {
      switch (deviceConfig.deviceId) {
        case "counter":
          history.push("/simulators/counterterminal");
          break;
        case "payzone":
          history.push("/simulators/payzone");
          break;
        case "mobile":
        case "mobiledrs":
          history.push("/simulators/mobile");
          break;
        case "sandbox":
          history.push(`/simulators/journeysandbox/master?deviceId=${deviceConfig.terminalId}`);
          break;
      }
    }
  };

  const getfilteredDevices = (type: string) => {
    if (type !== "sandbox") {
      return devices.filter((device) => device.type === type);
    }
    return devices;
  };

  const filteredDevices = selectedOption && devices && getfilteredDevices(selectedOption.id);

  return (
    <Authenticated>
      <main className="pb-8">
        <div className="py-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid items-start grid-cols-1 gap-2 mb-6 lg:grid-cols-4 lg:gap-8">
            {/* Select Device */}
            <div className="grid grid-cols-1 gap-4">
              <legend className="text-xl font-medium text-gray-900">{Constants.SELECT_DEVICE_TYPE}</legend>
              <Select
                placeholder={Constants.SELECT_DEVICE_TYPE}
                isSearchable={false}
                onChange={handleOnDeviceChange}
                formatOptionLabel={(item: DeviceType) => {
                  return (
                    <div className="flex bg-white -m-3 p-3">
                      <div className="flex-shrink-0">
                        <img className="w-8 h-8 rounded-full" src={item.image} alt="Device Icon" />
                      </div>
                      <div className="flex-1 min-w-0 mx-4 items-center">
                        <span className="text-sm font-medium text-gray-900 truncate">{item.name}</span>
                      </div>
                    </div>
                  );
                }}
                options={deviceTypes}
              />

              {!isLoading &&
                deviceConfig?.deviceId &&
                selectedOption &&
                devices &&
                filteredDevices &&
                filteredDevices?.length === 0 && (
                  <div className="inline-flex mt-1 ml-1">
                    <div className="flex items-center flex-shrink-0">
                      <img className="block w-auto h-3" src={Cross} alt="cross" />
                    </div>
                    <div className="ml-2 text-red-500">{"No Device IDs are linked to selected device type."}</div>
                  </div>
                )}
            </div>
            {/* Select Device Name */}
            {!isLoading && deviceConfig?.deviceId && filteredDevices !== null && filteredDevices.length > 0 && (
              <div className="grid grid-cols-1 gap-4">
                <legend className="text-xl font-medium text-gray-900">{Constants.SELECT_DEVICE_NAME}</legend>

                <Select
                  onChange={handleOnTerminalChange}
                  placeholder={Constants.SELECT_DEVICE_NAME}
                  value={selectedDevice}
                  isSearchable={false}
                  formatOptionLabel={(item: Device) => {
                    return (
                      <div className="flex bg-white -m-3 p-3">
                        <div className="flex-1 min-w-0 mx-4 items-center">
                          <span className="text-sm font-medium text-gray-900 truncate">{item.name}</span>
                        </div>
                      </div>
                    );
                  }}
                  options={filteredDevices}
                />

                {error && (
                  <div data-testid="error" className="inline-flex mt-1 ml-1">
                    <div className="flex items-center flex-shrink-0">
                      <img className="block w-auto h-3" src={Cross} alt="cross" />
                    </div>
                    <div className="ml-2 text-red-500">{error}</div>
                  </div>
                )}
              </div>
            )}
          </div>

          {isLoading && selectedOption && <div className="py-6 text-lg">{Constants.LOADING_DEVICES}</div>}

          {!isLoading && (
            <button
              onClick={onStartSimulatorClicked}
              className="flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-700 border border-transparent rounded-md shadow-sm hover:bg-gray-800"
            >
              {Constants.START_SIMULATOR}
            </button>
          )}
        </div>
      </main>
    </Authenticated>
  );
};

export default Setup;
