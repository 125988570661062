/* tslint:disable */
/* eslint-disable */
/**
 * Device Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.1.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface Alerts
 */
export interface Alerts {
    /**
     * Alert Type
     * @type {string}
     * @memberof Alerts
     */
    'type'?: AlertsTypeEnum;
    /**
     * Device ID
     * @type {string}
     * @memberof Alerts
     */
    'device_id': string;
    /**
     * Device name
     * @type {string}
     * @memberof Alerts
     */
    'device_name': string;
    /**
     * Device Type
     * @type {string}
     * @memberof Alerts
     */
    'device_type': AlertsDeviceTypeEnum;
    /**
     * Branch Id
     * @type {string}
     * @memberof Alerts
     */
    'branch_id': string;
    /**
     * Attributes changed from
     * @type {{ [key: string]: object; }}
     * @memberof Alerts
     */
    'before': { [key: string]: object; };
    /**
     * Attributes changed to
     * @type {{ [key: string]: object; }}
     * @memberof Alerts
     */
    'after': { [key: string]: object; };
    /**
     * Alert generated date
     * @type {string}
     * @memberof Alerts
     */
    'generated_at'?: string;
}

export const AlertsTypeEnum = {
    Warning: 'warning',
    Error: 'error'
} as const;

export type AlertsTypeEnum = typeof AlertsTypeEnum[keyof typeof AlertsTypeEnum];
export const AlertsDeviceTypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter',
    Mobile: 'mobile',
    Mobiledrs: 'mobiledrs'
} as const;

export type AlertsDeviceTypeEnum = typeof AlertsDeviceTypeEnum[keyof typeof AlertsDeviceTypeEnum];

/**
 *
 * @export
 * @interface CreateDeviceParams
 */
export interface CreateDeviceParams {
    /**
     * Id
     * @type {string}
     * @memberof CreateDeviceParams
     */
    'id': string;
    /**
     * Device Name
     * @type {string}
     * @memberof CreateDeviceParams
     */
    'name': string;
    /**
     * Device Type
     * @type {string}
     * @memberof CreateDeviceParams
     */
    'type': CreateDeviceParamsTypeEnum;
    /**
     * Node Id
     * @type {string}
     * @memberof CreateDeviceParams
     */
    'node_id': string;
    /**
     * Branch ID
     * @type {string}
     * @memberof CreateDeviceParams
     */
    'branch_id': string;
    /**
     * Device notes (optional)
     * @type {string}
     * @memberof CreateDeviceParams
     */
    'notes': string;
    /**
     * Activation State
     * @type {string}
     * @memberof CreateDeviceParams
     */
    'state': CreateDeviceParamsStateEnum;
    /**
     * Deactivation code
     * @type {string}
     * @memberof CreateDeviceParams
     */
    'deactivation_code'?: CreateDeviceParamsDeactivationCodeEnum;
}

export const CreateDeviceParamsTypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter',
    Mobile: 'mobile',
    Mobiledrs: 'mobiledrs'
} as const;

export type CreateDeviceParamsTypeEnum = typeof CreateDeviceParamsTypeEnum[keyof typeof CreateDeviceParamsTypeEnum];
export const CreateDeviceParamsStateEnum = {
    Inactive: 'inactive',
    Active: 'active'
} as const;

export type CreateDeviceParamsStateEnum = typeof CreateDeviceParamsStateEnum[keyof typeof CreateDeviceParamsStateEnum];
export const CreateDeviceParamsDeactivationCodeEnum = {
    FaultyDevice: 'faulty_device',
    BranchClosed: 'branch_closed',
    DeviceNoLongerInUse: 'device_no_longer_in_use'
} as const;

export type CreateDeviceParamsDeactivationCodeEnum = typeof CreateDeviceParamsDeactivationCodeEnum[keyof typeof CreateDeviceParamsDeactivationCodeEnum];

/**
 *
 * @export
 * @interface CreateUserParams
 */
export interface CreateUserParams {
    /**
     * Username for the new user
     * @type {string}
     * @memberof CreateUserParams
     */
    'username': string;
    /**
     * User role
     * @type {string}
     * @memberof CreateUserParams
     */
    'role': CreateUserParamsRoleEnum;
}

export const CreateUserParamsRoleEnum = {
    Admin: 'admin',
    User: 'user'
} as const;

export type CreateUserParamsRoleEnum = typeof CreateUserParamsRoleEnum[keyof typeof CreateUserParamsRoleEnum];

/**
 *
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * Device ID
     * @type {string}
     * @memberof Device
     */
    'id': string;
    /**
     * Device Name
     * @type {string}
     * @memberof Device
     */
    'name': string;
    /**
     * Device Type
     * @type {string}
     * @memberof Device
     */
    'type': DeviceTypeEnum;
    /**
     * Device Node Id
     * @type {string}
     * @memberof Device
     */
    'node_id': string;
    /**
     * Branch Id
     * @type {string}
     * @memberof Device
     */
    'branch_id': string;
    /**
     * Branch Name
     * @type {string}
     * @memberof Device
     */
    'branch_name': string;
    /**
     * Branch Address
     * @type {string}
     * @memberof Device
     */
    'branch_address': string;
    /**
     * Branch Postcode
     * @type {string}
     * @memberof Device
     */
    'branch_postcode': string;
    /**
     * Branch Organisation Unit Code
     * @type {string}
     * @memberof Device
     */
    'branch_org_unit_code': string;
    /**
     * Branch Organisation Unit Code Version
     * @type {string}
     * @memberof Device
     */
    'branch_org_unit_code_version': string;
    /**
     * Device notes
     * @type {string}
     * @memberof Device
     */
    'notes': string;
    /**
     * Record Creation Date Time
     * @type {string}
     * @memberof Device
     */
    'created_at': string;
    /**
     * Record Creation By
     * @type {string}
     * @memberof Device
     */
    'created_by': string;
    /**
     * Record Modified Date Time
     * @type {string}
     * @memberof Device
     */
    'modified_at': string;
    /**
     * Record Modified By
     * @type {string}
     * @memberof Device
     */
    'modified_by': string;
    /**
     * Last OTP Generated Date Time
     * @type {string}
     * @memberof Device
     */
    'last_otp_at': string;
    /**
     * Last OTP Generated By
     * @type {string}
     * @memberof Device
     */
    'last_otp_by': string;
    /**
     * Activation State
     * @type {string}
     * @memberof Device
     */
    'state'?: DeviceStateEnum;
    /**
     * Deactivation code
     * @type {string}
     * @memberof Device
     */
    'deactivation_code'?: DeviceDeactivationCodeEnum;
}

export const DeviceTypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter',
    Mobile: 'mobile',
    Mobiledrs: 'mobiledrs'
} as const;

export type DeviceTypeEnum = typeof DeviceTypeEnum[keyof typeof DeviceTypeEnum];
export const DeviceStateEnum = {
    Inactive: 'inactive',
    Active: 'active'
} as const;

export type DeviceStateEnum = typeof DeviceStateEnum[keyof typeof DeviceStateEnum];
export const DeviceDeactivationCodeEnum = {
    FaultyDevice: 'faulty_device',
    BranchClosed: 'branch_closed',
    DeviceNoLongerInUse: 'device_no_longer_in_use'
} as const;

export type DeviceDeactivationCodeEnum = typeof DeviceDeactivationCodeEnum[keyof typeof DeviceDeactivationCodeEnum];

/**
 *
 * @export
 * @interface Device2
 */
export interface Device2 {
    /**
     * Device ID
     * @type {string}
     * @memberof Device2
     */
    'id': string;
    /**
     * Device Name
     * @type {string}
     * @memberof Device2
     */
    'name': string;
    /**
     * Device Type
     * @type {string}
     * @memberof Device2
     */
    'type': Device2TypeEnum;
    /**
     * Device Node Id
     * @type {string}
     * @memberof Device2
     */
    'node_id': string;
    /**
     * Branch Id
     * @type {string}
     * @memberof Device2
     */
    'branch_id': string;
    /**
     * Branch Name
     * @type {string}
     * @memberof Device2
     */
    'branch_name': string;
    /**
     * Branch Address
     * @type {string}
     * @memberof Device2
     */
    'branch_address': string;
    /**
     * Branch Postcode
     * @type {string}
     * @memberof Device2
     */
    'branch_postcode': string;
    /**
     * Branch Organisation Unit Code
     * @type {string}
     * @memberof Device2
     */
    'branch_org_unit_code': string;
    /**
     * Branch Organisation Unit Code Version
     * @type {string}
     * @memberof Device2
     */
    'branch_org_unit_code_version': string;
    /**
     * Branch Location Type Code
     * @type {number}
     * @memberof Device2
     */
    'location_type_code': number;
    /**
     * Branch Location Type Name
     * @type {string}
     * @memberof Device2
     */
    'location_type_name': string;
    /**
     * Branch Trading Open Status
     * @type {string}
     * @memberof Device2
     */
    'trading_open_status': string;
    /**
     * Branch Trading Sub Status Code
     * @type {string}
     * @memberof Device2
     */
    'trading_sub_status_code': string;
    /**
     * Branch Trading Sub Status Name
     * @type {string}
     * @memberof Device2
     */
    'trading_sub_status_name': string;
    /**
     * Device notes
     * @type {string}
     * @memberof Device2
     */
    'notes': string;
    /**
     * Record Creation Date Time
     * @type {string}
     * @memberof Device2
     */
    'created_at': string;
    /**
     * Record Creation By
     * @type {string}
     * @memberof Device2
     */
    'created_by': string;
    /**
     * Record Modified Date Time
     * @type {string}
     * @memberof Device2
     */
    'modified_at': string;
    /**
     * Record Modified By
     * @type {string}
     * @memberof Device2
     */
    'modified_by': string;
    /**
     * Last OTP Generated Date Time
     * @type {string}
     * @memberof Device2
     */
    'last_otp_at': string;
    /**
     * Last OTP Generated By
     * @type {string}
     * @memberof Device2
     */
    'last_otp_by': string;
    /**
     * Activation State
     * @type {string}
     * @memberof Device2
     */
    'state'?: Device2StateEnum;
    /**
     * Deactivation code
     * @type {string}
     * @memberof Device2
     */
    'deactivation_code'?: Device2DeactivationCodeEnum;
}

export const Device2TypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter',
    Mobile: 'mobile',
    Mobiledrs: 'mobiledrs'
} as const;

export type Device2TypeEnum = typeof Device2TypeEnum[keyof typeof Device2TypeEnum];
export const Device2StateEnum = {
    Inactive: 'inactive',
    Active: 'active'
} as const;

export type Device2StateEnum = typeof Device2StateEnum[keyof typeof Device2StateEnum];
export const Device2DeactivationCodeEnum = {
    FaultyDevice: 'faulty_device',
    BranchClosed: 'branch_closed',
    DeviceNoLongerInUse: 'device_no_longer_in_use'
} as const;

export type Device2DeactivationCodeEnum = typeof Device2DeactivationCodeEnum[keyof typeof Device2DeactivationCodeEnum];

/**
 *
 * @export
 * @interface DeviceHistory
 */
export interface DeviceHistory {
    /**
     * Device ID
     * @type {string}
     * @memberof DeviceHistory
     */
    'id': string;
    /**
     * Device Type
     * @type {string}
     * @memberof DeviceHistory
     */
    'type': DeviceHistoryTypeEnum;
    /**
     * Device Modified Date Time
     * @type {string}
     * @memberof DeviceHistory
     */
    'modified_at': string;
    /**
     * Device Modified By
     * @type {string}
     * @memberof DeviceHistory
     */
    'modified_by': string;
    /**
     * Attributes changed from
     * @type {{ [key: string]: object; }}
     * @memberof DeviceHistory
     */
    'state_change_before'?: { [key: string]: object; };
    /**
     * Attributes changed to
     * @type {{ [key: string]: object; }}
     * @memberof DeviceHistory
     */
    'state_change_after'?: { [key: string]: object; };
}

export const DeviceHistoryTypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter',
    Mobile: 'mobile',
    Mobiledrs: 'mobiledrs'
} as const;

export type DeviceHistoryTypeEnum = typeof DeviceHistoryTypeEnum[keyof typeof DeviceHistoryTypeEnum];

/**
 *
 * @export
 * @interface DeviceSimulator
 */
export interface DeviceSimulator {
    /**
     * Device ID
     * @type {string}
     * @memberof DeviceSimulator
     */
    'id': string;
    /**
     * Device Name
     * @type {string}
     * @memberof DeviceSimulator
     */
    'name': string;
    /**
     * Device Type
     * @type {string}
     * @memberof DeviceSimulator
     */
    'type': DeviceSimulatorTypeEnum;
    /**
     * Device Node Id
     * @type {string}
     * @memberof DeviceSimulator
     */
    'node_id': string;
    /**
     * Branch Id
     * @type {string}
     * @memberof DeviceSimulator
     */
    'branch_id': string;
    /**
     * Record Creation Date Time
     * @type {string}
     * @memberof DeviceSimulator
     */
    'created_at': string;
}

export const DeviceSimulatorTypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter',
    Mobile: 'mobile',
    Mobiledrs: 'mobiledrs'
} as const;

export type DeviceSimulatorTypeEnum = typeof DeviceSimulatorTypeEnum[keyof typeof DeviceSimulatorTypeEnum];

/**
 *
 * @export
 * @interface Devices
 */
export interface Devices {
    /**
     *
     * @type {number}
     * @memberof Devices
     */
    'total': number;
    /**
     *
     * @type {number}
     * @memberof Devices
     */
    'items': number;
    /**
     *
     * @type {string}
     * @memberof Devices
     */
    'offset': string;
    /**
     *
     * @type {Array<Device>}
     * @memberof Devices
     */
    'devices': Array<Device>;
}
/**
 *
 * @export
 * @interface Devices2
 */
export interface Devices2 {
    /**
     *
     * @type {number}
     * @memberof Devices2
     */
    'total': number;
    /**
     *
     * @type {Array<Device2>}
     * @memberof Devices2
     */
    'items': Array<Device2>;
}
/**
 *
 * @export
 * @interface DevicesSimulator
 */
export interface DevicesSimulator {
    /**
     *
     * @type {number}
     * @memberof DevicesSimulator
     */
    'total': number;
    /**
     *
     * @type {Array<DeviceSimulator>}
     * @memberof DevicesSimulator
     */
    'items': Array<DeviceSimulator>;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     *
     * @type {Pagination}
     * @memberof InlineResponse200
     */
    'pagination': Pagination;
    /**
     *
     * @type {Array<DeviceHistory>}
     * @memberof InlineResponse200
     */
    'items': Array<DeviceHistory>;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     *
     * @type {Pagination}
     * @memberof InlineResponse2001
     */
    'pagination': Pagination;
    /**
     *
     * @type {Array<Alerts>}
     * @memberof InlineResponse2001
     */
    'items': Array<Alerts>;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     *
     * @type {Array<string>}
     * @memberof InlineResponse2002
     */
    'node_ids': Array<string>;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     *
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 *
 * @export
 * @interface Otp
 */
export interface Otp {
    /**
     *
     * @type {string}
     * @memberof Otp
     */
    'otp'?: string;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * Total number of records
     * @type {number}
     * @memberof Pagination
     */
    'total'?: number;
    /**
     * Total devices to return per request
     * @type {number}
     * @memberof Pagination
     */
    'limit'?: number;
    /**
     * Page number
     * @type {number}
     * @memberof Pagination
     */
    'page'?: number;
}
/**
 *
 * @export
 * @interface SaveDeviceParams
 */
export interface SaveDeviceParams {
    /**
     * Branch Id
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'id': string;
    /**
     * Device Name
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'name': string;
    /**
     * Device Type
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'type': SaveDeviceParamsTypeEnum;
    /**
     * Node Id
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'node_id': string;
    /**
     * Branch ID
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'branch_id': string;
    /**
     * Branch Name
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'branch_name': string;
    /**
     * Branch Address
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'branch_address': string;
    /**
     * Branch Postcode
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'branch_postcode': string;
    /**
     * Branch Org Unit Code
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'branch_org_unit_code': string;
    /**
     * Branch Org Unit Code Version
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'branch_org_unit_code_version': string;
    /**
     * Device notes (optional)
     * @type {string}
     * @memberof SaveDeviceParams
     */
    'notes': string;
}

export const SaveDeviceParamsTypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter',
    Mobile: 'mobile',
    Mobiledrs: 'mobiledrs'
} as const;

export type SaveDeviceParamsTypeEnum = typeof SaveDeviceParamsTypeEnum[keyof typeof SaveDeviceParamsTypeEnum];

/**
 *
 * @export
 * @interface UpdateDeviceOTPRequestParams
 */
export interface UpdateDeviceOTPRequestParams {
    /**
     *
     * @type {string}
     * @memberof UpdateDeviceOTPRequestParams
     */
    'id': string;
}
/**
 *
 * @export
 * @interface UpdateDeviceParams
 */
export interface UpdateDeviceParams {
    /**
     * Id
     * @type {string}
     * @memberof UpdateDeviceParams
     */
    'id': string;
    /**
     * Device Name
     * @type {string}
     * @memberof UpdateDeviceParams
     */
    'name': string;
    /**
     * Device Type
     * @type {string}
     * @memberof UpdateDeviceParams
     */
    'type': UpdateDeviceParamsTypeEnum;
    /**
     * Node Id
     * @type {string}
     * @memberof UpdateDeviceParams
     */
    'node_id'?: string;
    /**
     * Branch ID
     * @type {string}
     * @memberof UpdateDeviceParams
     */
    'branch_id': string;
    /**
     * Device notes (optional)
     * @type {string}
     * @memberof UpdateDeviceParams
     */
    'notes': string;
    /**
     * Activation State
     * @type {string}
     * @memberof UpdateDeviceParams
     */
    'state': UpdateDeviceParamsStateEnum;
    /**
     * Deactivation code
     * @type {string}
     * @memberof UpdateDeviceParams
     */
    'deactivation_code'?: UpdateDeviceParamsDeactivationCodeEnum;
}

export const UpdateDeviceParamsTypeEnum = {
    Payzone: 'payzone',
    Counter: 'counter',
    Mobile: 'mobile',
    Mobiledrs: 'mobiledrs'
} as const;

export type UpdateDeviceParamsTypeEnum = typeof UpdateDeviceParamsTypeEnum[keyof typeof UpdateDeviceParamsTypeEnum];
export const UpdateDeviceParamsStateEnum = {
    Inactive: 'inactive',
    Active: 'active'
} as const;

export type UpdateDeviceParamsStateEnum = typeof UpdateDeviceParamsStateEnum[keyof typeof UpdateDeviceParamsStateEnum];
export const UpdateDeviceParamsDeactivationCodeEnum = {
    Empty: '',
    FaultyDevice: 'faulty_device',
    BranchClosed: 'branch_closed',
    DeviceNoLongerInUse: 'device_no_longer_in_use'
} as const;

export type UpdateDeviceParamsDeactivationCodeEnum = typeof UpdateDeviceParamsDeactivationCodeEnum[keyof typeof UpdateDeviceParamsDeactivationCodeEnum];

/**
 *
 * @export
 * @interface UpdateUserParams
 */
export interface UpdateUserParams {
    /**
     * User role
     * @type {string}
     * @memberof UpdateUserParams
     */
    'role'?: UpdateUserParamsRoleEnum;
}

export const UpdateUserParamsRoleEnum = {
    Admin: 'admin',
    User: 'user'
} as const;

export type UpdateUserParamsRoleEnum = typeof UpdateUserParamsRoleEnum[keyof typeof UpdateUserParamsRoleEnum];

/**
 *
 * @export
 * @interface UpdateUserParams2
 */
export interface UpdateUserParams2 {
    /**
     * User role
     * @type {string}
     * @memberof UpdateUserParams2
     */
    'role'?: UpdateUserParams2RoleEnum;
    /**
     * User state
     * @type {boolean}
     * @memberof UpdateUserParams2
     */
    'enable'?: boolean;
    /**
     * Reason for disable user
     * @type {string}
     * @memberof UpdateUserParams2
     */
    'deactivation_code'?: UpdateUserParams2DeactivationCodeEnum;
}

export const UpdateUserParams2RoleEnum = {
    Admin: 'admin',
    User: 'user'
} as const;

export type UpdateUserParams2RoleEnum = typeof UpdateUserParams2RoleEnum[keyof typeof UpdateUserParams2RoleEnum];
export const UpdateUserParams2DeactivationCodeEnum = {
    Empty: '',
    NoLongerWithPostoffice: 'no_longer_with_postoffice',
    NotReceivedActivationLink: 'not_received_activation_link',
    NotYetVerified: 'not_yet_verified'
} as const;

export type UpdateUserParams2DeactivationCodeEnum = typeof UpdateUserParams2DeactivationCodeEnum[keyof typeof UpdateUserParams2DeactivationCodeEnum];

/**
 * User detail fetched successfully
 * @export
 * @interface User
 */
export interface User {
    /**
     * Registered Email ID
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Username
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * User role
     * @type {string}
     * @memberof User
     */
    'role': UserRoleEnum;
    /**
     * User is enabled or not
     * @type {boolean}
     * @memberof User
     */
    'enabled': boolean;
    /**
     * User is confirmed or pending
     * @type {string}
     * @memberof User
     */
    'user_status': string;
    /**
     * Record Updated Date Time
     * @type {string}
     * @memberof User
     */
    'modified_at': string;
    /**
     * Record Creation Date Time
     * @type {string}
     * @memberof User
     */
    'created_at': string;
}

export const UserRoleEnum = {
    Admin: 'admin',
    User: 'user'
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];

/**
 * User detail fetched successfully
 * @export
 * @interface User2
 */
export interface User2 {
    /**
     * User ID
     * @type {number}
     * @memberof User2
     */
    'id'?: number;
    /**
     * Registered Email ID
     * @type {string}
     * @memberof User2
     */
    'email': string;
    /**
     * Username
     * @type {string}
     * @memberof User2
     */
    'username': string;
    /**
     * User role
     * @type {string}
     * @memberof User2
     */
    'role': User2RoleEnum;
    /**
     * User is enabled or not
     * @type {boolean}
     * @memberof User2
     */
    'enabled': boolean;
    /**
     * Reason for disabling user
     * @type {string}
     * @memberof User2
     */
    'deactivation_code': User2DeactivationCodeEnum;
    /**
     * User is confirmed or pending
     * @type {string}
     * @memberof User2
     */
    'user_status': string;
    /**
     * Record Updated Date Time
     * @type {string}
     * @memberof User2
     */
    'modified_at': string;
    /**
     * Record Creation Date Time
     * @type {string}
     * @memberof User2
     */
    'created_at': string;
}

export const User2RoleEnum = {
    Admin: 'admin',
    User: 'user'
} as const;

export type User2RoleEnum = typeof User2RoleEnum[keyof typeof User2RoleEnum];
export const User2DeactivationCodeEnum = {
    NoLongerWithPostoffice: 'no_longer_with_postoffice',
    NotReceivedActivationLink: 'not_received_activation_link',
    NotYetVerified: 'not_yet_verified'
} as const;

export type User2DeactivationCodeEnum = typeof User2DeactivationCodeEnum[keyof typeof User2DeactivationCodeEnum];

/**
 *
 * @export
 * @interface Users
 */
export interface Users {
    /**
     *
     * @type {number}
     * @memberof Users
     */
    'count'?: number;
    /**
     *
     * @type {string}
     * @memberof Users
     */
    'offset'?: string;
    /**
     *
     * @type {Array<User>}
     * @memberof Users
     */
    'users'?: Array<User>;
}
/**
 *
 * @export
 * @interface Users2
 */
export interface Users2 {
    /**
     *
     * @type {number}
     * @memberof Users2
     */
    'total': number;
    /**
     *
     * @type {Array<User2>}
     * @memberof Users2
     */
    'items': Array<User2>;
}

/**
 * BuilderDeviceManagementApi - axios parameter creator
 * @export
 */
export const BuilderDeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1BuilderPutDeviceOtp: async (updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeviceOTPRequestParams' is not null or undefined
            assertParamExists('v1BuilderPutDeviceOtp', 'updateDeviceOTPRequestParams', updateDeviceOTPRequestParams)
            const localVarPath = `/builder/device/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Simulator required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceOTPRequestParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2BuilderPutDeviceOtp: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2BuilderPutDeviceOtp', 'id', id)
            const localVarPath = `/v2/builder/devices/{id}/generate-otp`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Simulator required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuilderDeviceManagementApi - functional programming interface
 * @export
 */
export const BuilderDeviceManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuilderDeviceManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1BuilderPutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BuilderPutDeviceOtp(updateDeviceOTPRequestParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2BuilderPutDeviceOtp(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2BuilderPutDeviceOtp(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuilderDeviceManagementApi - factory interface
 * @export
 */
export const BuilderDeviceManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuilderDeviceManagementApiFp(configuration)
    return {
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1BuilderPutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: any): AxiosPromise<Otp> {
            return localVarFp.v1BuilderPutDeviceOtp(updateDeviceOTPRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2BuilderPutDeviceOtp(id: string, options?: any): AxiosPromise<Otp> {
            return localVarFp.v2BuilderPutDeviceOtp(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuilderDeviceManagementApi - object-oriented interface
 * @export
 * @class BuilderDeviceManagementApi
 * @extends {BaseAPI}
 */
export class BuilderDeviceManagementApi extends BaseAPI {
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BuilderDeviceManagementApi
     */
    public v1BuilderPutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: AxiosRequestConfig) {
        return BuilderDeviceManagementApiFp(this.configuration).v1BuilderPutDeviceOtp(updateDeviceOTPRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {string} id Device id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuilderDeviceManagementApi
     */
    public v2BuilderPutDeviceOtp(id: string, options?: AxiosRequestConfig) {
        return BuilderDeviceManagementApiFp(this.configuration).v2BuilderPutDeviceOtp(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceManagementApi - axios parameter creator
 * @export
 */
export const DeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API to get device details by device id
         * @summary Get device
         * @param {string} id Device id to get details for
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1GetDevice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1GetDevice', 'id', id)
            const localVarPath = `/device/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1GetDevices: async (limit?: number, offset?: string, deviceIds?: string, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (deviceIds !== undefined) {
                localVarQueryParameter['device_ids'] = deviceIds;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a device
         * @summary Register the device
         * @param {SaveDeviceParams} saveDeviceParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PostDevice: async (saveDeviceParams: SaveDeviceParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveDeviceParams' is not null or undefined
            assertParamExists('v1PostDevice', 'saveDeviceParams', saveDeviceParams)
            const localVarPath = `/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveDeviceParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update a device
         * @param {SaveDeviceParams} saveDeviceParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PutDevice: async (saveDeviceParams: SaveDeviceParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveDeviceParams' is not null or undefined
            assertParamExists('v1PutDevice', 'saveDeviceParams', saveDeviceParams)
            const localVarPath = `/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveDeviceParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PutDeviceOtp: async (updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeviceOTPRequestParams' is not null or undefined
            assertParamExists('v1PutDeviceOtp', 'updateDeviceOTPRequestParams', updateDeviceOTPRequestParams)
            const localVarPath = `/device/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceOTPRequestParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Export devices to CSV file
         * @summary Export devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DevicesExport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/devices/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtain a paginated list of alerts on devices
         * @summary Get alerts information
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {'branch_id' | 'terminal_type'} [sortBy] Field by which data set will be ordered.
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {string} [q] Search term for filtering data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetAlerts: async (limit: number, page: number, sortBy?: 'branch_id' | 'terminal_type', sortDirection?: 'asc' | 'desc', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('v2GetAlerts', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('v2GetAlerts', 'page', page)
            const localVarPath = `/v2/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort-by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort-direction'] = sortDirection;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to get device details by device id
         * @summary Get device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetDevice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2GetDevice', 'id', id)
            const localVarPath = `/v2/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {string} [q] Search term for device-id and device-name
         * @param {'device_id' | 'device_name' | 'branch_id' | 'branch_name' | 'device_type' | 'modified_at'} [sortBy] Field by which data set will be ordered.
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetDevices: async (limit: number, page: number, q?: string, sortBy?: 'device_id' | 'device_name' | 'branch_id' | 'branch_name' | 'device_type' | 'modified_at', sortDirection?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('v2GetDevices', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('v2GetDevices', 'page', page)
            const localVarPath = `/v2/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort-by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort-direction'] = sortDirection;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtain a paginated list of devices with history
         * @summary Get list of devices along with update/history info
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {string} [q] Search term for filtering data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetDevicesHistory: async (limit: number, page: number, sortDirection?: 'asc' | 'desc', q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('v2GetDevicesHistory', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('v2GetDevicesHistory', 'page', page)
            const localVarPath = `/v2/devices/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort-direction'] = sortDirection;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to get available node-id\'s given the branchid
         * @summary Get available node-id\'s for branch
         * @param {string} branchId Branch Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetNodeid: async (branchId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('v2GetNodeid', 'branchId', branchId)
            const localVarPath = `/v2/node-ids/{branch_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a device
         * @summary Register the device
         * @param {CreateDeviceParams} createDeviceParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PostDevice: async (createDeviceParams: CreateDeviceParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeviceParams' is not null or undefined
            assertParamExists('v2PostDevice', 'createDeviceParams', createDeviceParams)
            const localVarPath = `/v2/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeviceParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update a device
         * @param {string} id Device id
         * @param {UpdateDeviceParams} updateDeviceParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PutDevice: async (id: string, updateDeviceParams: UpdateDeviceParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2PutDevice', 'id', id)
            // verify required parameter 'updateDeviceParams' is not null or undefined
            assertParamExists('v2PutDevice', 'updateDeviceParams', updateDeviceParams)
            const localVarPath = `/v2/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PutDeviceOtp: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2PutDeviceOtp', 'id', id)
            const localVarPath = `/v2/devices/{id}/generate-otp`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceManagementApi - functional programming interface
 * @export
 */
export const DeviceManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * API to get device details by device id
         * @summary Get device
         * @param {string} id Device id to get details for
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1GetDevice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetDevice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1GetDevices(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Devices>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetDevices(limit, offset, deviceIds, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers a device
         * @summary Register the device
         * @param {SaveDeviceParams} saveDeviceParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1PostDevice(saveDeviceParams: SaveDeviceParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PostDevice(saveDeviceParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update a device
         * @param {SaveDeviceParams} saveDeviceParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1PutDevice(saveDeviceParams: SaveDeviceParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PutDevice(saveDeviceParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1PutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PutDeviceOtp(updateDeviceOTPRequestParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Export devices to CSV file
         * @summary Export devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2DevicesExport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2DevicesExport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtain a paginated list of alerts on devices
         * @summary Get alerts information
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {'branch_id' | 'terminal_type'} [sortBy] Field by which data set will be ordered.
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {string} [q] Search term for filtering data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GetAlerts(limit: number, page: number, sortBy?: 'branch_id' | 'terminal_type', sortDirection?: 'asc' | 'desc', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetAlerts(limit, page, sortBy, sortDirection, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to get device details by device id
         * @summary Get device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GetDevice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetDevice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {string} [q] Search term for device-id and device-name
         * @param {'device_id' | 'device_name' | 'branch_id' | 'branch_name' | 'device_type' | 'modified_at'} [sortBy] Field by which data set will be ordered.
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GetDevices(limit: number, page: number, q?: string, sortBy?: 'device_id' | 'device_name' | 'branch_id' | 'branch_name' | 'device_type' | 'modified_at', sortDirection?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Devices2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetDevices(limit, page, q, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtain a paginated list of devices with history
         * @summary Get list of devices along with update/history info
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {string} [q] Search term for filtering data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GetDevicesHistory(limit: number, page: number, sortDirection?: 'asc' | 'desc', q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetDevicesHistory(limit, page, sortDirection, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to get available node-id\'s given the branchid
         * @summary Get available node-id\'s for branch
         * @param {string} branchId Branch Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GetNodeid(branchId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetNodeid(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers a device
         * @summary Register the device
         * @param {CreateDeviceParams} createDeviceParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2PostDevice(createDeviceParams: CreateDeviceParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2PostDevice(createDeviceParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Update a device
         * @param {string} id Device id
         * @param {UpdateDeviceParams} updateDeviceParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2PutDevice(id: string, updateDeviceParams: UpdateDeviceParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2PutDevice(id, updateDeviceParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2PutDeviceOtp(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2PutDeviceOtp(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceManagementApi - factory interface
 * @export
 */
export const DeviceManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceManagementApiFp(configuration)
    return {
        /**
         * API to get device details by device id
         * @summary Get device
         * @param {string} id Device id to get details for
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1GetDevice(id: string, options?: any): AxiosPromise<Device> {
            return localVarFp.v1GetDevice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1GetDevices(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: any): AxiosPromise<Devices> {
            return localVarFp.v1GetDevices(limit, offset, deviceIds, q, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a device
         * @summary Register the device
         * @param {SaveDeviceParams} saveDeviceParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PostDevice(saveDeviceParams: SaveDeviceParams, options?: any): AxiosPromise<void> {
            return localVarFp.v1PostDevice(saveDeviceParams, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update a device
         * @param {SaveDeviceParams} saveDeviceParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PutDevice(saveDeviceParams: SaveDeviceParams, options?: any): AxiosPromise<void> {
            return localVarFp.v1PutDevice(saveDeviceParams, options).then((request) => request(axios, basePath));
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: any): AxiosPromise<Otp> {
            return localVarFp.v1PutDeviceOtp(updateDeviceOTPRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Export devices to CSV file
         * @summary Export devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2DevicesExport(options?: any): AxiosPromise<any> {
            return localVarFp.v2DevicesExport(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtain a paginated list of alerts on devices
         * @summary Get alerts information
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {'branch_id' | 'terminal_type'} [sortBy] Field by which data set will be ordered.
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {string} [q] Search term for filtering data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetAlerts(limit: number, page: number, sortBy?: 'branch_id' | 'terminal_type', sortDirection?: 'asc' | 'desc', q?: string, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.v2GetAlerts(limit, page, sortBy, sortDirection, q, options).then((request) => request(axios, basePath));
        },
        /**
         * API to get device details by device id
         * @summary Get device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetDevice(id: string, options?: any): AxiosPromise<Device2> {
            return localVarFp.v2GetDevice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {string} [q] Search term for device-id and device-name
         * @param {'device_id' | 'device_name' | 'branch_id' | 'branch_name' | 'device_type' | 'modified_at'} [sortBy] Field by which data set will be ordered.
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetDevices(limit: number, page: number, q?: string, sortBy?: 'device_id' | 'device_name' | 'branch_id' | 'branch_name' | 'device_type' | 'modified_at', sortDirection?: 'asc' | 'desc', options?: any): AxiosPromise<Devices2> {
            return localVarFp.v2GetDevices(limit, page, q, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtain a paginated list of devices with history
         * @summary Get list of devices along with update/history info
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {string} [q] Search term for filtering data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetDevicesHistory(limit: number, page: number, sortDirection?: 'asc' | 'desc', q?: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v2GetDevicesHistory(limit, page, sortDirection, q, options).then((request) => request(axios, basePath));
        },
        /**
         * API to get available node-id\'s given the branchid
         * @summary Get available node-id\'s for branch
         * @param {string} branchId Branch Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetNodeid(branchId: string, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.v2GetNodeid(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a device
         * @summary Register the device
         * @param {CreateDeviceParams} createDeviceParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PostDevice(createDeviceParams: CreateDeviceParams, options?: any): AxiosPromise<void> {
            return localVarFp.v2PostDevice(createDeviceParams, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update a device
         * @param {string} id Device id
         * @param {UpdateDeviceParams} updateDeviceParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PutDevice(id: string, updateDeviceParams: UpdateDeviceParams, options?: any): AxiosPromise<void> {
            return localVarFp.v2PutDevice(id, updateDeviceParams, options).then((request) => request(axios, basePath));
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PutDeviceOtp(id: string, options?: any): AxiosPromise<Otp> {
            return localVarFp.v2PutDeviceOtp(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceManagementApi - object-oriented interface
 * @export
 * @class DeviceManagementApi
 * @extends {BaseAPI}
 */
export class DeviceManagementApi extends BaseAPI {
    /**
     * API to get device details by device id
     * @summary Get device
     * @param {string} id Device id to get details for
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v1GetDevice(id: string, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v1GetDevice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v1GetDevices(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v1GetDevices(limit, offset, deviceIds, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers a device
     * @summary Register the device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v1PostDevice(saveDeviceParams: SaveDeviceParams, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v1PostDevice(saveDeviceParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update a device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v1PutDevice(saveDeviceParams: SaveDeviceParams, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v1PutDevice(saveDeviceParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v1PutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v1PutDeviceOtp(updateDeviceOTPRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Export devices to CSV file
     * @summary Export devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v2DevicesExport(options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v2DevicesExport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtain a paginated list of alerts on devices
     * @summary Get alerts information
     * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
     * @param {number} page Page number
     * @param {'branch_id' | 'terminal_type'} [sortBy] Field by which data set will be ordered.
     * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
     * @param {string} [q] Search term for filtering data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v2GetAlerts(limit: number, page: number, sortBy?: 'branch_id' | 'terminal_type', sortDirection?: 'asc' | 'desc', q?: string, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v2GetAlerts(limit, page, sortBy, sortDirection, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to get device details by device id
     * @summary Get device
     * @param {string} id Device id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v2GetDevice(id: string, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v2GetDevice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
     * @param {number} page Page number
     * @param {string} [q] Search term for device-id and device-name
     * @param {'device_id' | 'device_name' | 'branch_id' | 'branch_name' | 'device_type' | 'modified_at'} [sortBy] Field by which data set will be ordered.
     * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v2GetDevices(limit: number, page: number, q?: string, sortBy?: 'device_id' | 'device_name' | 'branch_id' | 'branch_name' | 'device_type' | 'modified_at', sortDirection?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v2GetDevices(limit, page, q, sortBy, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtain a paginated list of devices with history
     * @summary Get list of devices along with update/history info
     * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
     * @param {number} page Page number
     * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
     * @param {string} [q] Search term for filtering data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v2GetDevicesHistory(limit: number, page: number, sortDirection?: 'asc' | 'desc', q?: string, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v2GetDevicesHistory(limit, page, sortDirection, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to get available node-id\'s given the branchid
     * @summary Get available node-id\'s for branch
     * @param {string} branchId Branch Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v2GetNodeid(branchId: string, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v2GetNodeid(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers a device
     * @summary Register the device
     * @param {CreateDeviceParams} createDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v2PostDevice(createDeviceParams: CreateDeviceParams, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v2PostDevice(createDeviceParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Update a device
     * @param {string} id Device id
     * @param {UpdateDeviceParams} updateDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v2PutDevice(id: string, updateDeviceParams: UpdateDeviceParams, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v2PutDevice(id, updateDeviceParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {string} id Device id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceManagementApi
     */
    public v2PutDeviceOtp(id: string, options?: AxiosRequestConfig) {
        return DeviceManagementApiFp(this.configuration).v2PutDeviceOtp(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SimulatorDeviceManagementApi - axios parameter creator
 * @export
 */
export const SimulatorDeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1SimulatorGetDevices: async (limit?: number, offset?: string, deviceIds?: string, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/simulator/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Simulator required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (deviceIds !== undefined) {
                localVarQueryParameter['device_ids'] = deviceIds;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1SimulatorPutDeviceOtp: async (updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeviceOTPRequestParams' is not null or undefined
            assertParamExists('v1SimulatorPutDeviceOtp', 'updateDeviceOTPRequestParams', updateDeviceOTPRequestParams)
            const localVarPath = `/simulator/device/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Simulator required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceOTPRequestParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to get device details (simulator) by device id
         * @summary Get device (simulator)
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SimulatorGetDevice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2SimulatorGetDevice', 'id', id)
            const localVarPath = `/v2/simulator/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get device details, given the device-ids (simulator)
         * @summary Get list of devices (simulator)
         * @param {Array<string>} ids Device ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SimulatorGetDevices: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('v2SimulatorGetDevices', 'ids', ids)
            const localVarPath = `/v2/simulator/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SimulatorPutDeviceOtp: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2SimulatorPutDeviceOtp', 'id', id)
            const localVarPath = `/v2/simulator/devices/{id}/generate-otp`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Simulator required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SimulatorDeviceManagementApi - functional programming interface
 * @export
 */
export const SimulatorDeviceManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SimulatorDeviceManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1SimulatorGetDevices(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Devices>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SimulatorGetDevices(limit, offset, deviceIds, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1SimulatorPutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SimulatorPutDeviceOtp(updateDeviceOTPRequestParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to get device details (simulator) by device id
         * @summary Get device (simulator)
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SimulatorGetDevice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceSimulator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2SimulatorGetDevice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get device details, given the device-ids (simulator)
         * @summary Get list of devices (simulator)
         * @param {Array<string>} ids Device ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SimulatorGetDevices(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesSimulator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2SimulatorGetDevices(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2SimulatorPutDeviceOtp(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2SimulatorPutDeviceOtp(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SimulatorDeviceManagementApi - factory interface
 * @export
 */
export const SimulatorDeviceManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SimulatorDeviceManagementApiFp(configuration)
    return {
        /**
         * Obtain a paginated list of devices
         * @summary Get list of devices
         * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
         * @param {string} [offset] Key of last item returned in previous request - used for pagination
         * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
         * @param {string} [q] Search term for device-id and device-name
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1SimulatorGetDevices(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: any): AxiosPromise<Devices> {
            return localVarFp.v1SimulatorGetDevices(limit, offset, deviceIds, q, options).then((request) => request(axios, basePath));
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1SimulatorPutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: any): AxiosPromise<Otp> {
            return localVarFp.v1SimulatorPutDeviceOtp(updateDeviceOTPRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * API to get device details (simulator) by device id
         * @summary Get device (simulator)
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SimulatorGetDevice(id: string, options?: any): AxiosPromise<DeviceSimulator> {
            return localVarFp.v2SimulatorGetDevice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get device details, given the device-ids (simulator)
         * @summary Get list of devices (simulator)
         * @param {Array<string>} ids Device ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SimulatorGetDevices(ids: Array<string>, options?: any): AxiosPromise<DevicesSimulator> {
            return localVarFp.v2SimulatorGetDevices(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * An OTP can only be generated if it has a branch association
         * @summary Regenerate OTP for device
         * @param {string} id Device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2SimulatorPutDeviceOtp(id: string, options?: any): AxiosPromise<Otp> {
            return localVarFp.v2SimulatorPutDeviceOtp(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SimulatorDeviceManagementApi - object-oriented interface
 * @export
 * @class SimulatorDeviceManagementApi
 * @extends {BaseAPI}
 */
export class SimulatorDeviceManagementApi extends BaseAPI {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SimulatorDeviceManagementApi
     */
    public v1SimulatorGetDevices(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig) {
        return SimulatorDeviceManagementApiFp(this.configuration).v1SimulatorGetDevices(limit, offset, deviceIds, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SimulatorDeviceManagementApi
     */
    public v1SimulatorPutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: AxiosRequestConfig) {
        return SimulatorDeviceManagementApiFp(this.configuration).v1SimulatorPutDeviceOtp(updateDeviceOTPRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to get device details (simulator) by device id
     * @summary Get device (simulator)
     * @param {string} id Device id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatorDeviceManagementApi
     */
    public v2SimulatorGetDevice(id: string, options?: AxiosRequestConfig) {
        return SimulatorDeviceManagementApiFp(this.configuration).v2SimulatorGetDevice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get device details, given the device-ids (simulator)
     * @summary Get list of devices (simulator)
     * @param {Array<string>} ids Device ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatorDeviceManagementApi
     */
    public v2SimulatorGetDevices(ids: Array<string>, options?: AxiosRequestConfig) {
        return SimulatorDeviceManagementApiFp(this.configuration).v2SimulatorGetDevices(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {string} id Device id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimulatorDeviceManagementApi
     */
    public v2SimulatorPutDeviceOtp(id: string, options?: AxiosRequestConfig) {
        return SimulatorDeviceManagementApiFp(this.configuration).v2SimulatorPutDeviceOtp(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API to get user details by user id
         * @summary Get user
         * @param {string} username Username to get details for
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1GetUser: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('v1GetUser', 'username', username)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtain a paginated list of users
         * @summary Get list of users
         * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
         * @param {string} [offset] offset to retrieve next list of users by pagination
         * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1GetUsers: async (limit?: number, offset?: string, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user with provided details
         * @summary Add new user
         * @param {CreateUserParams} createUserParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PostUser: async (createUserParams: CreateUserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserParams' is not null or undefined
            assertParamExists('v1PostUser', 'createUserParams', createUserParams)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to update user details
         * @summary Update user details
         * @param {string} username Username to update details for
         * @param {UpdateUserParams} updateUserParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PutUser: async (username: string, updateUserParams: UpdateUserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('v1PutUser', 'username', username)
            // verify required parameter 'updateUserParams' is not null or undefined
            assertParamExists('v1PutUser', 'updateUserParams', updateUserParams)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to get user details by user id
         * @summary Get user
         * @param {string} id User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2GetUser', 'id', id)
            const localVarPath = `/v2/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtain a paginated list of users
         * @summary Get list of users
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
         * @param {'username' | 'email' | 'user_status' | 'role' | 'modified_at'} [sortBy] Field by which data set will be ordered.
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetUsers: async (limit: number, page: number, q?: string, sortBy?: 'username' | 'email' | 'user_status' | 'role' | 'modified_at', sortDirection?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('v2GetUsers', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('v2GetUsers', 'page', page)
            const localVarPath = `/v2/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort-by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort-direction'] = sortDirection;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user with provided details
         * @summary Add new user
         * @param {CreateUserParams} createUserParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PostUser: async (createUserParams: CreateUserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserParams' is not null or undefined
            assertParamExists('v2PostUser', 'createUserParams', createUserParams)
            const localVarPath = `/v2/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to update user details
         * @summary Update user details
         * @param {string} id User id
         * @param {UpdateUserParams2} updateUserParams2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PutUser: async (id: string, updateUserParams2: UpdateUserParams2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2PutUser', 'id', id)
            // verify required parameter 'updateUserParams2' is not null or undefined
            assertParamExists('v2PutUser', 'updateUserParams2', updateUserParams2)
            const localVarPath = `/v2/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserParams2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Export users to CSV file
         * @summary Export users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersExport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication DeviceManager required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * API to get user details by user id
         * @summary Get user
         * @param {string} username Username to get details for
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1GetUser(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetUser(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtain a paginated list of users
         * @summary Get list of users
         * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
         * @param {string} [offset] offset to retrieve next list of users by pagination
         * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1GetUsers(limit?: number, offset?: string, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetUsers(limit, offset, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new user with provided details
         * @summary Add new user
         * @param {CreateUserParams} createUserParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1PostUser(createUserParams: CreateUserParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PostUser(createUserParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to update user details
         * @summary Update user details
         * @param {string} username Username to update details for
         * @param {UpdateUserParams} updateUserParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1PutUser(username: string, updateUserParams: UpdateUserParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PutUser(username, updateUserParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to get user details by user id
         * @summary Get user
         * @param {string} id User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GetUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtain a paginated list of users
         * @summary Get list of users
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
         * @param {'username' | 'email' | 'user_status' | 'role' | 'modified_at'} [sortBy] Field by which data set will be ordered.
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GetUsers(limit: number, page: number, q?: string, sortBy?: 'username' | 'email' | 'user_status' | 'role' | 'modified_at', sortDirection?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetUsers(limit, page, q, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new user with provided details
         * @summary Add new user
         * @param {CreateUserParams} createUserParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2PostUser(createUserParams: CreateUserParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2PostUser(createUserParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to update user details
         * @summary Update user details
         * @param {string} id User id
         * @param {UpdateUserParams2} updateUserParams2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2PutUser(id: string, updateUserParams2: UpdateUserParams2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2PutUser(id, updateUserParams2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Export users to CSV file
         * @summary Export users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersExport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2UsersExport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagementApiFp(configuration)
    return {
        /**
         * API to get user details by user id
         * @summary Get user
         * @param {string} username Username to get details for
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1GetUser(username: string, options?: any): AxiosPromise<User> {
            return localVarFp.v1GetUser(username, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtain a paginated list of users
         * @summary Get list of users
         * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
         * @param {string} [offset] offset to retrieve next list of users by pagination
         * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1GetUsers(limit?: number, offset?: string, q?: string, options?: any): AxiosPromise<Users> {
            return localVarFp.v1GetUsers(limit, offset, q, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user with provided details
         * @summary Add new user
         * @param {CreateUserParams} createUserParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PostUser(createUserParams: CreateUserParams, options?: any): AxiosPromise<void> {
            return localVarFp.v1PostUser(createUserParams, options).then((request) => request(axios, basePath));
        },
        /**
         * API to update user details
         * @summary Update user details
         * @param {string} username Username to update details for
         * @param {UpdateUserParams} updateUserParams
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1PutUser(username: string, updateUserParams: UpdateUserParams, options?: any): AxiosPromise<void> {
            return localVarFp.v1PutUser(username, updateUserParams, options).then((request) => request(axios, basePath));
        },
        /**
         * API to get user details by user id
         * @summary Get user
         * @param {string} id User id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetUser(id: string, options?: any): AxiosPromise<User2> {
            return localVarFp.v2GetUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtain a paginated list of users
         * @summary Get list of users
         * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
         * @param {number} page Page number
         * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
         * @param {'username' | 'email' | 'user_status' | 'role' | 'modified_at'} [sortBy] Field by which data set will be ordered.
         * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GetUsers(limit: number, page: number, q?: string, sortBy?: 'username' | 'email' | 'user_status' | 'role' | 'modified_at', sortDirection?: 'asc' | 'desc', options?: any): AxiosPromise<Users2> {
            return localVarFp.v2GetUsers(limit, page, q, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user with provided details
         * @summary Add new user
         * @param {CreateUserParams} createUserParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PostUser(createUserParams: CreateUserParams, options?: any): AxiosPromise<void> {
            return localVarFp.v2PostUser(createUserParams, options).then((request) => request(axios, basePath));
        },
        /**
         * API to update user details
         * @summary Update user details
         * @param {string} id User id
         * @param {UpdateUserParams2} updateUserParams2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2PutUser(id: string, updateUserParams2: UpdateUserParams2, options?: any): AxiosPromise<void> {
            return localVarFp.v2PutUser(id, updateUserParams2, options).then((request) => request(axios, basePath));
        },
        /**
         * Export users to CSV file
         * @summary Export users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersExport(options?: any): AxiosPromise<any> {
            return localVarFp.v2UsersExport(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * API to get user details by user id
     * @summary Get user
     * @param {string} username Username to get details for
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public v1GetUser(username: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).v1GetUser(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtain a paginated list of users
     * @summary Get list of users
     * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
     * @param {string} [offset] offset to retrieve next list of users by pagination
     * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public v1GetUsers(limit?: number, offset?: string, q?: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).v1GetUsers(limit, offset, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new user with provided details
     * @summary Add new user
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public v1PostUser(createUserParams: CreateUserParams, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).v1PostUser(createUserParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to update user details
     * @summary Update user details
     * @param {string} username Username to update details for
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public v1PutUser(username: string, updateUserParams: UpdateUserParams, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).v1PutUser(username, updateUserParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to get user details by user id
     * @summary Get user
     * @param {string} id User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public v2GetUser(id: string, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).v2GetUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtain a paginated list of users
     * @summary Get list of users
     * @param {number} limit Total records to return per request - default is 30 (maximum - 200)
     * @param {number} page Page number
     * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
     * @param {'username' | 'email' | 'user_status' | 'role' | 'modified_at'} [sortBy] Field by which data set will be ordered.
     * @param {'asc' | 'desc'} [sortDirection] Order for &#x60;sort_by&#x60; attribute
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public v2GetUsers(limit: number, page: number, q?: string, sortBy?: 'username' | 'email' | 'user_status' | 'role' | 'modified_at', sortDirection?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).v2GetUsers(limit, page, q, sortBy, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new user with provided details
     * @summary Add new user
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public v2PostUser(createUserParams: CreateUserParams, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).v2PostUser(createUserParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to update user details
     * @summary Update user details
     * @param {string} id User id
     * @param {UpdateUserParams2} updateUserParams2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public v2PutUser(id: string, updateUserParams2: UpdateUserParams2, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).v2PutUser(id, updateUserParams2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Export users to CSV file
     * @summary Export users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public v2UsersExport(options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).v2UsersExport(options).then((request) => request(this.axios, this.basePath));
    }
}
