(window as unknown as { _env: Record<string, string> })._env ??= {};

const environment =
  process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development"
    ? process.env
    : (window as unknown as { _env: Record<string, string> })._env;

const baseApiUrl =
  environment.REACT_APP_STAGE === "local"
    ? "https://api.spm-dev.com"
    : `https://api.spm-${environment.REACT_APP_STAGE}.com`;
const baseDomainUrl =
  environment.REACT_APP_STAGE === "local"
    ? "http://localhost:8080"
    : `https://simulator.spm-${environment.REACT_APP_STAGE}.com/apps`;

const apiConfig = {
  baseApi: baseApiUrl,
  api: `${baseApiUrl}/simulator`,
  deviceManagementApi: `${baseApiUrl}/device-management`,
  journeyApi: `${baseApiUrl}/user-journey`,
  apiDocuments: `${baseApiUrl}/ApiDocuments`,
  labelGenerationApi: `${baseApiUrl}/label-generator`,
  boUtilsApi: `${baseApiUrl}/BboTestUtils`,
  apps: {
    payzone: `${baseDomainUrl}/payzone/index.html`,
    counterterminal: `${baseDomainUrl}/counterterminal/index.html`,
    journeysandbox: `${baseDomainUrl}/journeysandbox/master/index.html`,
    mobile: `${baseDomainUrl}/mobile/index.html`,
  },
  isProduction: environment.REACT_APP_STAGE === "prod",
  isDevelopment: environment.REACT_APP_STAGE === "dev",
  awsSimulatorConfig: {
    mandatorySignIn: true,
    region: "eu-west-2",
    userPoolId: environment.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: environment.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
  reactAppDeviceManagerUrl: environment.REACT_APP_DEVICE_MANAGER_URL,
  reactAppServerRoot: environment.REACT_APP_SERVER_ROOT,
  reactAppDevicesClientId: environment.REACT_APP_DEVICES_CLIENT_ID,
  reactAppPolPedTpvOverride: environment.REACT_APP_POL_PED_TPV_OVERRIDE,
  reactAppPolDeviceServerHost: environment.REACT_APP_POL_DEVICE_SERVER_HOST,
  reactAppPolDeviceServerSimulated: environment.REACT_APP_POL_DEVICE_SERVER_SIMULATED,
  launchDarklyKey: environment.REACT_APP_LD_KEY,
};

export default apiConfig;

export const enablerConfig = {
  journey: {
    rootUrl: baseApiUrl,
  },
  despatch: { rootUrl: baseApiUrl },
  tokeniser: {
    rootUrl: baseApiUrl,
  },
  bankAccountValidation: { rootUrl: baseApiUrl, version: "v1" },
  paymentsAndBanking: { rootUrl: baseApiUrl },
  estimatedDeliveryDate: { rootUrl: baseApiUrl },
  country: { rootUrl: baseApiUrl },
  product: { rootUrl: baseApiUrl },
  insurance: { rootUrl: baseApiUrl },
  governmentId: { rootUrl: baseApiUrl },
  postal: { rootUrl: baseApiUrl },
  postcodeLookup: { rootUrl: baseApiUrl },
  mails: { rootUrl: baseApiUrl },
  travelMoney: { rootUrl: baseApiUrl },
  billpay: { rootUrl: baseApiUrl },
  boeNotesExchange: { rootUrl: baseApiUrl },
  pbne: { rootUrl: baseApiUrl },
  imovo: { rootUrl: baseApiUrl },
  dropandgo: { rootUrl: baseApiUrl },
  payout: { rootUrl: baseApiUrl },
  giftcard: { rootUrl: baseApiUrl },
  moneygram: { rootUrl: baseApiUrl },
  fmcc: { rootUrl: baseApiUrl },
  ekycVerification: { rootUrl: baseApiUrl },
};
