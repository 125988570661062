import { useState } from "react";
import axios, { AxiosError } from "axios";
import jwt_decode from "jwt-decode";
import apiConfig from "../../../config";
import { DeviceApi } from "../../../openapi/device-api";
import { DeviceSimulator as SimulatorDevice } from "../../../openapi/device-management-api/api";
import { AccessToken, AuthenticationResult, Tokens, UseAuthHook } from "./types";
import { isTokenExpired } from "./helpers";

export const refreshAuthToken = async (refreshToken: string): Promise<AuthenticationResult | undefined> => {
  try {
    // TODO: Move this to a constant
    const authResponse = await axios.post(
      "https://cognito-idp.eu-west-2.amazonaws.com",
      {
        ClientId: apiConfig.reactAppDevicesClientId,
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
        },
      },
      {
        headers: {
          "content-type": " application/x-amz-json-1.1",
          "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        },
      }
    );

    if (authResponse.status === 200) {
      const response = authResponse.data.AuthenticationResult as AuthenticationResult;
      return response;
    } else {
      return undefined;
    }
  } catch (err) {
    console.error(`Error refreshing token: ${err}`);
    return undefined;
  }
};

export const getDeviceTokens = async (
  deviceApi: DeviceApi,
  selectedDevice: SimulatorDevice,
  refreshAuthTokenMethod: (refreshToken: string) => Promise<AuthenticationResult | undefined> = refreshAuthToken
): Promise<Tokens | undefined> => {
  if (!selectedDevice) {
    console.error("No device selected for getTokens");
    return;
  }
  if (deviceApi?.deviceIdTokenGet === undefined) {
    console.error("No deviceApi defined for getTokens");
    return;
  }
  try {
    const tokensResponse = await deviceApi.deviceIdTokenGet(selectedDevice.id);

    if (tokensResponse.status === 200 && tokensResponse.data) {
      const { accessToken, idToken, refreshToken } = tokensResponse.data;

      const tokenDetails = jwt_decode<AccessToken>(accessToken);

      if (isTokenExpired(tokenDetails.exp)) {
        console.error(`Token for device ID: "${selectedDevice.id}" was expired - will refresh`);
        const tokenResult = await refreshAuthTokenMethod(refreshToken);
        if (!tokenResult) {
          return {};
        }

        // TODO: remove this from this method
        const res = await deviceApi.deviceIdTokenPut(selectedDevice.id, {
          accessToken: tokenResult.AccessToken,
          idToken: tokenResult.IdToken,
          refreshToken: refreshToken,
        });

        if (res.status !== 200) {
          // if we can't save the tokens then we'll need to get new ones
          // we'll erase the tokens if it fails as the tokens will no longer reflect their actual values after refreshing
          // TODO: remove this from this method
          await deviceApi.deviceIdTokenDelete(selectedDevice.id);
          return {};
        }

        return {
          refreshToken,
          idToken: tokenResult.IdToken,
          accessToken: tokenResult.AccessToken,
        };
      }

      return {
        accessToken,
        idToken,
        refreshToken,
      };
    }
  } catch (e) {
    console.error(e);
    return { accessToken: `${e}` };
  }
};

export const useAuth = (): UseAuthHook => {
  const [tokens, setTokens] = useState<Tokens>({});

  axios.interceptors.response.use(undefined, async (res: AxiosError) => {
    // if we get an auth error then retry after refreshing the token with the new access token
    if (res.response?.status === 401) {
      await refreshAuthToken(tokens.refreshToken);
      return axios.request({
        ...res,
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
    }

    return Promise.reject(res);
  });

  const getTokens = async (deviceApi: DeviceApi, selectedDevice: SimulatorDevice) => {
    const response = await getDeviceTokens(deviceApi, selectedDevice);
    if (response !== undefined) {
      setTokens(response);
    }
  };

  return { tokens, getTokens };
};
