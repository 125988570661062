export enum Constants {
  // SetUp page constants
  SELECT_DEVICE_TYPE = "Select Device Type",
  SELECT_DEVICE_NAME = "Select Device Name",
  LOADING_DEVICES = "Loading devices, please wait...",
  START_SIMULATOR = "Start Simulator",
  PAYZONE = "Payzone",
  COUNTER_TERMINAL = "Counter Terminal",
  MOBILE = "Mobile",
  MOBILE_DRS = "Mobile DRS",
  SANDBOX = "Sandbox",
  PLEASE_ENTER_TERMINAL_ID = "Please enter a terminal ID ",
  PLEASE_COMPLETE_FORM_SELECTION = "Please complete form selection",
  // Sandbox page constants
  END_SESSION_WARNING = "Are you sure you want to close your session?",
  INVALID_JOURNEY_ERROR_TITLE = "Invalid Journey Script",
  INVALID_JOURNEY_ERROR_DESC = "Please validate the journey json and paste again.",
  JOURNEY_SANDBOX = "Journey Sandbox",
  JOURNEY_SANDBOX_SIMULATOR = "Journey Sandbox Simulator",
  END_SIMULATOR_SESSION = "End Simulator Session",
  JOURNEY_SCRIPT = "Journey Script",
  JOURNEY_PREVIEW = "Journey Preview",
  JOURNEY_SCRIPT_PLACEHOLDER = "Please write/paste the journey script here",
  // Payzone page constants
  PAYZONE_SIMULATOR = "Payzone Simulator",
  DEVICE_TYPE = "Device Type",
  DEVICE_ID = "Device ID",
  ENGINE_VERSION = "Journey engine version",
  COPY_AUTH_TOKEN = "Copy Auth Token",
  SIMULATOR = "Simulator",
  PAYZONE_RESOLUTION = "720px*1280px",
  // CT page constants
  COUNTER_TERMINAL_SIMULATOR = "Counter Terminal Simulator",
  COUNTER_TERMINAL_RESOLUTION = "1920px*1080px",
  // Mobile page constants (second device)
  MOBILE_SIMULATOR = "Mobile Simulator",
  MOBILE_RESOLUTION = "480px*853px",
  // Device page constants
  DEVICE_NAME = "Device Name",
  ACTION = "Action",
  LINK_DEVICE = "Link Device",
  UNLINK_DEVICE = "Unlink Device",
  DEVICE_MANAGER = "device manager",
  ADD_DEVICE_DESC = "You can manage devices the simulator can access by linking your device to your account. If you are looking to " +
    "register a new device or view existing devices that are currently not linked to your account, please vist the ",
  // Login page constants
  PAGE_NOT_FOUND = "404 Page Not Found",
  VERIFICATION_CODE_MSG = " It can take up to 10 minutes to receive a verification code using a Post Office " +
    "email address",
  PLEASE_NOTE = "Please Note:",
  // Receipt page constants
  ENTER_TEMPLATE = "Please enter template",
  ENTER_CONTEXT_VARIABLE = "Please enter context variables",
  ENTER_CONTEXT = "Please enter context",
  ENTER_TRANSLATIONS = "Please enter translations",
  COMPILE = "COMPILE",
  ENTER_HANDLEBARS_TEMPLATE = "Please enter handlebars template",
  RECEIPT_TEMPLATE = "Receipt Template",
  RECEIPT_CONTEXT = "Context (JSON)",
  RECEIPT_PREVIEW = "Receipt Preview",
  //API methods
  FETCH_JOURNEY_METHOD_NAME = "v5DraftJourneyGet",
  FETCH_SUBJOURNEY_METHOD_NAME = "simulatorV5SubJourneyGet",
}

export const HTTP_STATUS = {
  OK: 200,
  NOT_FOUND: 404,
};
